<template>
  <div class="content">
    <h1>lee.color.hsvToRgb(color)</h1>
    <p class="cm-variable">将Hsv颜色转RGB颜色值</p>
    <h3>参数</h3>
    <ul>
      <li><code>color</code><em>(String)</em>:Hsv颜色值</li>
    </ul>
    <h3>例子</h3>
    <div class="jscode">
      <div class="jscontent" v-highlight>
        <pre><code class="language-javascript">let color=lee.color.hsvToRgb("60,50,100")
console.log(color)</code></pre>
      </div>
    </div>
    <div style="height: 15px;"></div>
    <div class="jscode" style=" border: solid 1px #dfe2e5;">
      <div class="jscontent" style="padding:24px 0;display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <div class="color-plate" @mousedown="press=true;getColor(press)" @mouseup="press=false"
             @mousemove="getColor(press)" @touchstart="press=true;getColor1(press)" @touchend="press=false" @touchmove="getColor1(press)"></div>
        <div class="color-view" :style="'background:'+rgbColor">{{rgbColor}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            rgbColor: '',
            press: false
        }
    },
    methods: {
        getColor(pd) {
            let lee=window.lee
            if (pd) {
                let {width} = event.currentTarget.getBoundingClientRect()
                let x = event.offsetX - width / 2
                let y = width / 2 - event.offsetY
                let hsv = this.getHsv(x, y,width / 2)//得到HSV值
                let rgb = lee.color.hsvToRgb(hsv)
                this.rgbColor='rgb('+rgb.r+','+rgb.g+','+rgb.b+')'
            }
        },
        getColor1(pd) {
            let lee=window.lee
            if (pd) {
                let {width} = event.currentTarget.getBoundingClientRect()
                let rx=event.targetTouches[0].clientX-event.currentTarget.offsetLeft
                let ry=event.targetTouches[0].clientY-(event.currentTarget.offsetTop-document.documentElement.scrollTop)
                let x = rx - width / 2
                let y = width / 2 - ry
                let hsv = this.getHsv(x, y,width/2)//得到HSV值
                this.rgbColor = lee.color.hsvToRgb(hsv)
            }
        },
        getHsv(x, y,w) {
            let datax = Math.abs(x)
            let datay = Math.abs(y)
            let Tan = datay / datax //正切
            // 斜边
            const hypotenuse = Math.sqrt(x * x + y * y)
            let hypotenuseReturn = Math.round((100 / w) * hypotenuse)
            let dataAtan = Math.round(Number(Math.atan(Tan)) / (Math.PI / 180))//获得角度
            let angle = 0
            if (x > 0 && y > 0) {
                angle = 90 - dataAtan
            } else if (x > 0 && y < 0) {
                angle = 90 + dataAtan
            } else if (x < 0 && y < 0) {
                angle = 270 - dataAtan
            } else if (x < 0 && y > 0) {
                angle = 270 + dataAtan
            }
            return angle+","+hypotenuseReturn+","+100//h,s,v

        }
    }

}
</script>
<style scoped>
.color-plate {
  max-width: 300px;
  max-height: 300px;
  width:50vw;
  height: 50vw;
  border-radius: 100%;
  background: conic-gradient(red, yellow, lime, aqua, blue, fuchsia, red);
}

.color-view {
  max-width: 300px;
  width: 50vw;
  height: 66px;
  border: solid 1px #aaa;
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>
